import 'bootstrap-touchspin';

export default class Variants {
  init() {
    $(document).on('change', '.select-variants', function() {
      var $form = $(this).parents('form');

      var $quantity = $(this).find('option:selected').data('quantity');

      if ($quantity !== "undefined") {
        if (parseInt($quantity) <= 0) {
          $form.find('.add .add-to-cart').prop('disabled', true)
                                         .text('Indisponible')
          ;

          return false;
        } else {
          $form.find('.add .add-to-cart').prop('disabled', false)
                                         .text('Ajouter au panier')
          ;
        }
      }

      if ($form.length) {
        $form.find('.qty').hide(500, function() {
          $form.find('.add').show();
        });
      }
    });

    $(document).on('click', '.product-variants-item .variants', function() {
      var combinationId = $(this).data('value');

      $('.product-variants select option').each(function(i, el) {
        if (parseInt($(el).val()) == combinationId) {
          $(el).prop('selected', true).change();
        }
      })
    });

    if ($('.select-product-attribute').length > 0) {
      $(window).on('click', function(e) {
        if ($(e.target).parents('.product-variants').length == 0) {
          $('.select-product-attribute.active').removeClass('active');
          return true;
        }
      });
    }

    $(document).on('click', '.select-product-attribute', function(event) {
      if ($('.select-product-attribute.active').length == 1) {
        $('.select-product-attribute.active').removeClass('active');
        return true;
      }

      $('.select-product-attribute').addClass('active');

      $(document).on('click', '.select-product-attribute-list li', function(event) {
        var combinationId = $(this).data('value');

        var name = $(this).find(".attribute").text();
        var image = $(this).find("img").attr('src');
        var price = $(this).find(".price-selector").text();

        $('.selected-attribute li').find(".attribute").removeClass('bold');
        $('.selected-attribute span.attribute').text(name);
        $('.selected-attribute img').attr('src', image);
        $('.selected-attribute span.price-selector').text(price);

        $(this).find(".attribute").addClass('bold');

        $('.product-variants select option').each(function(i, el) {
          if (parseInt($(el).val()) == combinationId) {
            $(el).prop('selected', true).change();
          }
        });
      });
    });
  }
}
