import * as Toastr from 'toastr';

export default class AddToCart {
  init() {
    prestashop.on('updateCart', (resp) => {
      if (resp.reason == undefined) {
        return;
      }

      if (resp.reason.linkAction == undefined) {
        return;
      }

      if (!resp.reason.linkAction.includes("add-to-cart", "remove-to-cart")) {
        return;
      }

      if (resp.reason.linkAction == "remove-to-cart") {
        this.shakeEffect(resp);

        return;
      }

      if (resp.resp.success && (resp.resp.hasError == undefined || !resp.resp.hasError)) {
        var $identifier = '.product-quantity-'+resp.reason.idProduct;
        $($identifier+' .add').hide(function(e) {
          $(this).parents($identifier).find('.qty').show();
        });

        this.shakeEffect(resp);

        if (resp.reason.products !== undefined) {
          if (Object.keys(resp.reason.products).length > 1) {
            prestashop.emit('displayNotification', 'success', Object.keys(resp.reason.products).length + ' produits ajoutés à votre panier')
          } else {
            prestashop.emit('displayNotification', 'success', '1 produit ajouté à votre panier <a href="' + prestashop.urls.pages.cart + '" class="btn btn-primary btn-toast">Voir mon panier</a>')
          }
        } else {
          prestashop.emit('displayNotification', 'success', '1 produit ajouté à votre panier <a href="' + prestashop.urls.pages.cart + '" class="btn btn-primary btn-toast">Voir mon panier</a>')
        }
      }
    });
  }

  shakeEffect(resp) {
    if (parseInt(resp.resp.cart.products_count) > 0) {
      $('.cart-products-count').fadeIn();
    } else {
      $('.cart-products-count').fadeOut();
    }

    $('.cart-products-count').text(resp.resp.cart.products_count);
    $('.cart-products-count').addClass('shake');

    setTimeout(function() {
      $('.cart-products-count').removeClass('shake');
    }, 3000);
  }
}
