import 'bootstrap-touchspin';

import Toast from './toast';

export default class Touchspin {
  init() {
    this.createInputFile();
    this.createProductSpin();

    prestashop.on('updatedProduct', function (event) {
      var touchSpin = new Touchspin();
      touchSpin.createInputFile();

      if (event && event.product_minimal_quantity) {
        const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
        const quantityInputSelector = '#quantity_wanted';
        let quantityInput = $(quantityInputSelector);

        // @see http://www.virtuosoft.eu/code/bootstrap-touchspin/ about Bootstrap TouchSpin
        quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
      }

      $($('.tabs .nav-link.active').attr('href')).addClass('active').removeClass('fade');
      $('.js-product-images-modal').replaceWith(event.product_images_modal);
    });
  }

  createProductSpin() {
    const $quantityInputSelector = '#quantity_wanted, .quantity_wanted';
    const $quantityInput = $($quantityInputSelector);

    $quantityInput.TouchSpin({
        buttondown_class: 'btn js-touchspin',
        buttonup_class: 'btn js-touchspin',
        min: parseInt($quantityInput.attr('min'), 10),
        max: 1000000
    });

    var $currentQuantityInput = 1;

    $(document).on('change keyup', $quantityInputSelector, (e) => {
      $(e.currentTarget).trigger('touchspin.stopspin');

      var op = "up";
      if ($(e.currentTarget).val() < $currentQuantityInput) {
        op = "down";
      }

      var $form = $(e.target).parents('form');
      $currentQuantityInput = $(e.currentTarget).val();

      var data = {
        update: 1,
        action: 'update',
        ajax: 1,
        qty: 1,
        id_product: parseInt($form.find('input[name="id_product"]').val()),
        token: prestashop.static_token,
        op: op
      };

      var attributeId = parseInt($form.find('.select-variants').data('productAttribute'));
      var group = parseInt($form.find('.select-variants').val());

      if (attributeId > 0) {
        if (!isNaN(group)) {
          data['group['+attributeId+']'] = group;
        }
      } else {
        data['id_product_attribute'] = group;
      }

      const promises = [];

      $.ajax({
        url: $(e.currentTarget).data('cartUrl'),
        method: 'POST',
        data: data,
        dataType: 'json',
        beforeSend: function (jqXHR) {
          promises.push(jqXHR);
        }
      }).then(function (resp) {
        // Refresh cart preview
        prestashop.emit('updateCart', {
          reason: {
            linkAction: "remove-to-cart",
          },
          resp: resp
        });

        if (resp.success) {
          let toast = new Toast();
          if ("up" == op) {
            toast.notify('success', '1 produit ajouté à votre panier <a href="' + prestashop.urls.pages.cart + '" class="btn btn-primary btn-toast">Voir mon panier</a>');
          } else {
            toast.notify('success', '1 produit retiré de votre panier <a href="' + prestashop.urls.pages.cart + '" class="btn btn-primary btn-toast">Voir mon panier</a>');
          }
        }
      }).fail((resp) => {
        prestashop.emit('handleError', {eventType: 'updateProductQuantityInCart', resp: resp})
      });
    });
  }

  createInputFile()
    {
      $('.js-file-input').on('change', (event) => {
        let target, file;

        if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
          $(target).prev().text(file.name);
        }
      });
    }
}
